import { defaultTeamPrefs, defaultUserPrefs } from '@arcadehq/shared/helpers'

import { AccountMixin } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export interface HotspotDefaults {
  backgroundColor: string | undefined
  textColor: string | undefined

  setTeamBackgroundColor: (backgroundColor?: string) => Promise<boolean>
  setUserBackgroundColor: (backgroundColor?: string) => Promise<boolean>
  setTeamTextColor: (textColor?: string) => Promise<boolean>
  setUserTextColor: (textColor?: string) => Promise<boolean>

  setBothColors: (
    backgroundColor: string,
    textColor: string
  ) => Promise<boolean>
}

// TODO do something to bake in the global default values here

export const hotspotDefaults: AccountMixin<HotspotDefaults> = (
  core,
  { team, userProfile }
) => ({
  get backgroundColor() {
    if (team?.currentSubscriber) {
      return getSubfield(team, 'prefs', 'bgColor') ?? defaultTeamPrefs.bgColor
    }
    if (userProfile) {
      return (
        getSubfield(userProfile, 'prefs', 'bgColor') ?? defaultTeamPrefs.bgColor
      )
    }
  },

  get textColor() {
    if (team?.currentSubscriber) {
      return getSubfield(team, 'prefs', 'fgColor') ?? defaultTeamPrefs.fgColor
    }
    if (userProfile) {
      return (
        getSubfield(userProfile, 'prefs', 'textColor') ??
        defaultUserPrefs.textColor
      )
    }
  },

  setTeamBackgroundColor(backgroundColor: string | undefined) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'prefs',
        'bgColor',
        backgroundColor,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setUserBackgroundColor(backgroundColor: string | undefined) {
    if (userProfile) {
      return setOrDeleteSubfield(
        userProfile,
        'prefs',
        'bgColor',
        backgroundColor,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setTeamTextColor(textColor: string | undefined) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'prefs',
        'fgColor',
        textColor,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setUserTextColor(textColor: string | undefined) {
    if (userProfile) {
      return setOrDeleteSubfield(
        userProfile,
        'prefs',
        'textColor',
        textColor,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setBothColors(backgroundColor: string, textColor: string) {
    if (team) {
      const prefs = {
        ...(team.prefs ?? {}),
        bgColor: backgroundColor,
        textColor,
      }

      return team.update(
        {
          prefs,
        },
        core.userId
      )
    }
    // TODO set user profile
    return Promise.resolve(false)
  },
})
