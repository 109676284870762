"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cn = void 0;
const clsx_1 = require("clsx");
const tailwind_merge_1 = require("tailwind-merge");
/**
 * A wrapper around `classNames` that merges the result with tailwind-merge.
 * This allows us to use tailwind classes in combination with classNames.
 * In particular, this allows us to use classes that target the same CSS property
 * in a conditional manner.
 * e.g. `cn('text-gray-600', { 'text-red-600': condition })` will result in
 * `text-red-600` if `condition` is true, and `text-gray-600` otherwise.
 */
function cn(...inputs) {
    return (0, tailwind_merge_1.twMerge)((0, clsx_1.clsx)(inputs));
}
exports.cn = cn;
