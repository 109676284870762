"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStepOrder = exports.sortHotspotsForAnimation = exports.getNextNonEmptyAutoStep = exports.getNextNonEmptyIndexedStep = exports.getFirstNonEmptyStep = exports.isCropped = exports.hasBlur = void 0;
const types_1 = require("../types");
function hasBlur(step) {
    return (!!step.imageEditorState?.redaction &&
        step.imageEditorState?.redaction.length > 0);
}
exports.hasBlur = hasBlur;
function isCropped(step) {
    if (!step.imageEditorState?.crop || !step.size?.height || !step.size?.width) {
        return false;
    }
    return (step.imageEditorState.crop.height !== step.size.height ||
        step.imageEditorState.crop.width !== step.size.width ||
        step.imageEditorState.crop.x !== 0 ||
        step.imageEditorState.crop.y !== 0);
}
exports.isCropped = isCropped;
function getFirstNonEmptyStep(steps) {
    return steps.find(step => step.type !== types_1.StepType.Empty);
}
exports.getFirstNonEmptyStep = getFirstNonEmptyStep;
function getNextNonEmptyIndexedStep(steps, fromStep, allowRestart) {
    const fromStepIndex = steps.findIndex(s => s.id === fromStep.id);
    return (getFirstNonEmptyStep(steps.slice(fromStepIndex + 1)) ??
        (allowRestart ? getFirstNonEmptyStep(steps) : undefined));
}
exports.getNextNonEmptyIndexedStep = getNextNonEmptyIndexedStep;
function getNextNonEmptyAutoStep(steps, fromStep, allowRestart) {
    let nextAutoStepId;
    if (fromStep.type === types_1.StepType.Image ||
        (fromStep.type === types_1.StepType.Overlay &&
            (!fromStep.paths || !fromStep.paths?.length))) {
        nextAutoStepId = fromStep.hotspots?.[0]?.targetId;
    }
    else if (fromStep.type === types_1.StepType.Overlay && fromStep.paths?.length) {
        nextAutoStepId = fromStep.paths.find(path => path.pathType === 'step')?.targetStepId;
    }
    let nextAutoStep;
    if (nextAutoStepId) {
        nextAutoStep = steps.find(({ id }) => id === nextAutoStepId);
    }
    if (!nextAutoStep) {
        nextAutoStep = getNextNonEmptyIndexedStep(steps, fromStep, allowRestart);
    }
    return nextAutoStep;
}
exports.getNextNonEmptyAutoStep = getNextNonEmptyAutoStep;
// Sort hotspots/callouts so that they indexes attempt to match the type.
// This sort prioritizes hotspots over callouts so that hotspots are
// always animated.
// TODO: We could likely improve this even further by splitting hotspots and
// callouts entirely and then finding the closest ones of each type between
// steps.
function sortHotspotsForAnimation(a, b) {
    return (a.style ?? 'pulsating') > (b.style ?? 'pulsating') ? -1 : 1;
}
exports.sortHotspotsForAnimation = sortHotspotsForAnimation;
// Returns an array of steps in an Arcade in the order they would appear during
// autoplay. Steps may repeat.
function getStepOrder(steps, maxNumberOfSteps) {
    const sequence = [];
    let nextStep = getFirstNonEmptyStep(steps);
    while (!!nextStep && sequence.length < maxNumberOfSteps) {
        sequence.push(nextStep);
        nextStep = getNextNonEmptyAutoStep(steps, nextStep, false);
    }
    return sequence;
}
exports.getStepOrder = getStepOrder;
