"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlan = void 0;
function getPlan(userProfile, team) {
    let plan;
    // The `undefined` state of `userProfile.currentSubscriber` means that the user has never been Pro.
    // For teams, we don't have that distinction, `currentSubscriber` is either `trueish` or `falseish`.
    if (userProfile.currentSubscriber) {
        plan = 'Pro';
    }
    else if (userProfile.currentSubscriber === false &&
        userProfile.customerId) {
        plan = 'Free (Pro Churned)';
    }
    else {
        plan = 'Free';
    }
    if (team && userProfile.isActiveMemberOfTeam) {
        if (team.currentSubscriber && team.customerId) {
            plan = 'Growth';
        }
        else if (team.currentSubscriber && !team.customerId) {
            plan = 'Growth Trial';
        }
        else if (!team.currentSubscriber && team.customerId) {
            plan = (plan + ' (Growth Churned)');
        }
        else if (!team.currentSubscriber && !team.customerId) {
            plan = (plan + ' (Growth Trial Ended)');
        }
    }
    return plan;
}
exports.getPlan = getPlan;
