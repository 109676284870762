import { AccountMixin } from '../AccountCore'

export interface Intercom {
  userHash: string | null
}

export const intercom: AccountMixin<Intercom> = (_core, { userProfile }) => ({
  get userHash() {
    return userProfile.intercomUserHash ?? null
  },
})
