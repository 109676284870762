"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultTeamPrefs = exports.defaultUserPrefs = void 0;
const types_1 = require("../types");
exports.defaultUserPrefs = {
    textColor: '#FFFFFF',
    bgColor: '#7E22CE',
    fontFamily: 'Inter',
};
exports.defaultTeamPrefs = {
    fontFamily: 'Inter',
    bgColor: '#2142E7',
    fgColor: '#FFFFFF',
    bgImage: undefined,
    wrapper: types_1.FlowWrapper.browserLight,
    showStartOverlay: false,
    startOverlayButtonText: undefined,
    showFlowNavIndicator: false,
};
