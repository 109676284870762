"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmbedCode = void 0;
const constants_1 = require("../constants");
const customVariables_1 = require("./customVariables");
function getEmbedCode({ flowId, customLinkId, aspectRatio, queryParams, baseUrl, }) {
    if (isNaN(aspectRatio))
        aspectRatio = 2 / 3;
    const embedUrl = `${baseUrl}/${flowId}${customLinkId ? `/${customLinkId}` : ''}?embed${(0, customVariables_1.getCustomValuesQueryString)(queryParams, '&')}`;
    const paddingBottom = `calc(${aspectRatio * 100}% + ${constants_1.BROWSER_BAR_OR_CAPTION_HEIGHT}px)`;
    return `<div style="position: relative; padding-bottom: ${paddingBottom}; height: 0;"><iframe src="${embedUrl}" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"></iframe></div>`;
}
exports.getEmbedCode = getEmbedCode;
