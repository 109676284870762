"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStripeIds = void 0;
const constants_1 = require("../constants");
const getStripeIds = (secretKey, includePromoPrice = false) => {
    if (includePromoPrice) {
        return secretKey.startsWith('sk_live')
            ? {
                ...constants_1.STRIPE_IDS.live,
                pro: {
                    id: constants_1.STRIPE_IDS.live.pro.id,
                    monthly: 'price_1Mgcm0CDgCbpE4wjNUbHwQgg',
                    yearly: 'price_1MgcmQCDgCbpE4wjOaA09tni',
                    billingPortalConfigId: 'bpc_1MgiBRCDgCbpE4wjy2JfTjQZ',
                },
            }
            : {
                ...constants_1.STRIPE_IDS.test,
                pro: {
                    id: constants_1.STRIPE_IDS.test.pro.id,
                    monthly: 'price_1MgcqqCDgCbpE4wjAGOYLAzz',
                    yearly: 'price_1Mgcr2CDgCbpE4wjRIlTI2ov',
                    billingPortalConfigId: 'bpc_1MgfSJCDgCbpE4wjnXaGx53k',
                },
            };
    }
    else {
        return secretKey.startsWith('sk_live') ? constants_1.STRIPE_IDS.live : constants_1.STRIPE_IDS.test;
    }
};
exports.getStripeIds = getStripeIds;
