import { Feature } from '@arcadehq/shared/types'

import { AccountMixin } from '../AccountCore'
import { getFeatureValue } from '../helpers'

export interface Extension {
  mayUsePageEditor: boolean
}

export const extension: AccountMixin<Extension> = (
  _core,
  { team, userProfile }
) => ({
  get mayUsePageEditor() {
    return getFeatureValue(Feature.PageEditor, userProfile, team) === true
  },
})
