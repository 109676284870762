"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.variablesUsedInFlow = exports.missingVariablesMessage = exports.isStepMissingCustomVariables = exports.isTextMissingCustomVariables = exports.getTemplateQueryParamsFromVariables = exports.getCustomVariablesQueryString = exports.getCustomValuesQueryString = exports.getCustomValuesFromQuery = exports.withCustomVariables = void 0;
const TEMPLATE_VALUE = 'INSERT_VALUE_HERE';
// Matches e.g. {{ my_custom_variable }}
const CUSTOM_VARIABLE_SYNTAX_REGEX = /{{[ ]*([a-z_\-0-9]+)[ ]*}}/dgi;
// Matches e.g. variable.my_custom_variable
const CUSTOM_VARIABLE_QUERY_REGEX = /^variable.([a-z_\-0-9]+)$/i;
// This function parses text for custom variables and replaces matching syntax
// with provided custom values or the corresponding variables' fallback values.
// Along with the resulting string, it returns a set of used variables as well
// as missing variables referenced in text but not available on the account.
function withCustomVariablesVerbose(text, variables, values = {}) {
    if (!variables)
        variables = {};
    const used = new Set();
    const missing = new Set();
    let pos = 0;
    let match = null;
    let result = '';
    // Find each instance of matching syntax
    while ((match = CUSTOM_VARIABLE_SYNTAX_REGEX.exec(text))) {
        const name = match[1];
        const exists = !!variables[name];
        // Determine whether this is an allowed custom variable
        if (exists)
            used.add(name);
        else
            missing.add(name);
        // Determine the value to insert, using a fallback if no custom value is
        // passed or empty string if the variable is not allowed. The latter seems
        // a bit better for the viewer experience if there's a missing variable...
        const valueProvided = !!values[name] && values[name] !== TEMPLATE_VALUE
            ? values[name]
            : variables[name]?.fallback;
        const value = exists ? valueProvided : '';
        // Append the substring of text between the previous match and this match to
        // our result, as well as the appropriate variable value.
        const [[start, end]] = match.indices;
        result += text.slice(pos, start);
        result += value;
        pos = end;
    }
    // Append the substring of text after the final match to our result
    result += text.slice(pos);
    return { result, used, missing };
}
function withCustomVariables(text, variables, values) {
    return withCustomVariablesVerbose(text, variables, values).result;
}
exports.withCustomVariables = withCustomVariables;
function getCustomValuesFromQuery(query) {
    const customValues = {};
    for (const [k, v] of Object.entries(query)) {
        const value = Array.isArray(v) ? v[v.length - 1] : v;
        if (!value)
            continue;
        const match = CUSTOM_VARIABLE_QUERY_REGEX.exec(k);
        if (!match)
            continue;
        const name = match[1];
        customValues[name] = value;
    }
    return customValues;
}
exports.getCustomValuesFromQuery = getCustomValuesFromQuery;
function getCustomValuesQueryString(query = {}, startingChar = '?') {
    const customValues = [];
    for (const [k, v] of Object.entries(query)) {
        const value = Array.isArray(v) ? v[v.length - 1] : v;
        if (value && CUSTOM_VARIABLE_QUERY_REGEX.exec(k))
            customValues.push([k, value]);
    }
    return customValues.length
        ? `${startingChar}${customValues.map(([k, v]) => `${k}=${v}`).join('&')}`
        : '';
}
exports.getCustomValuesQueryString = getCustomValuesQueryString;
function getCustomVariablesQueryString(variables) {
    const keys = Object.keys(variables ?? {});
    return keys.length
        ? `?${keys.map(k => `variable.${k}=${TEMPLATE_VALUE}`).join('&')}`
        : '';
}
exports.getCustomVariablesQueryString = getCustomVariablesQueryString;
function getTemplateQueryParamsFromVariables(variables) {
    if (!variables)
        return {};
    return Object.fromEntries(Object.keys(variables).map(name => [`variable.${name}`, TEMPLATE_VALUE]));
}
exports.getTemplateQueryParamsFromVariables = getTemplateQueryParamsFromVariables;
function isTextMissingCustomVariables(text, variables) {
    return (text &&
        !!withCustomVariablesVerbose(text, variables, undefined).missing.size);
}
exports.isTextMissingCustomVariables = isTextMissingCustomVariables;
function isStepMissingCustomVariables(step, variables) {
    return getStepFieldsWithVariables(step).some(t => isTextMissingCustomVariables(t, variables));
}
exports.isStepMissingCustomVariables = isStepMissingCustomVariables;
function missingVariablesMessage(name) {
    return `${name} is attempting to reference variables that do not exist.`;
}
exports.missingVariablesMessage = missingVariablesMessage;
function variablesUsedInFlow(flow) {
    const used = new Set();
    for (const step of flow.steps) {
        for (const text of getStepFieldsWithVariables(step)) {
            const usedVariables = Array.from(withCustomVariablesVerbose(text ?? '', flow.customVariables).used);
            for (const variable of usedVariables)
                used.add(variable);
        }
    }
    for (const text of [flow.name, flow.description]) {
        const usedVariables = Array.from(withCustomVariablesVerbose(text ?? '', flow.customVariables).used);
        for (const variable of usedVariables)
            used.add(variable);
    }
    return used;
}
exports.variablesUsedInFlow = variablesUsedInFlow;
function getStepFieldsWithVariables(step) {
    return [
        step.title,
        step.subtitle,
        step.buttonText,
        ...step.hotspots.map(h => h.label),
        ...(step.paths ? step.paths.map(p => p.buttonText) : []),
    ];
}
