"use strict";
/*
 * Note: If you add a new feature, you must also add it to the following file:
 * packages/shared/src/helpers/defaultFeatures.ts
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Feature = void 0;
var Feature;
(function (Feature) {
    Feature["NoArcadeBranding"] = "No Arcade Branding";
    Feature["Analytics"] = "Analytics";
    Feature["PublishEventsToHost"] = "Publish Events to Host";
    Feature["DisableIPTracking"] = "Disable IP Tracking";
    Feature["PreventIndexing"] = "Prevent Indexing";
    Feature["DoNotTrack"] = "Do Not Track";
    Feature["DisableInternal3rdPartyTrackers"] = "Disable Internal 3rd Party Trackers";
    Feature["PaywallArcadeLimit"] = "Paywall Arcade Limit";
    Feature["FlowInsights"] = "Flow Insights";
    Feature["EditAccessToArcadesIsControlled"] = "Edit Access to Arcades is Controlled";
    // Temporary features:
    // To disable a temporary feature:
    // - Remove it from the code
    // - Remove it from Firestore (userProfiles, Teams and Global)
    // - Remove it from Retool
    Feature["RemotionExport"] = "Remotion Export";
    Feature["PageEditor"] = "Page Editor";
    Feature["CustomVariables"] = "Custom Variables";
})(Feature = exports.Feature || (exports.Feature = {}));
